import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const AdvancedBlockRendererClient = ({ content }) => {
    const navigate = useNavigate();

    const handleLink = (url) => {
        if (url.includes('/#contact')) {
            navigate('/#contact');
        } else {
            window.open(url, '_blank')
            // window.location = url;
        }
    };

    return <BlocksRenderer
        content={content}
        blocks={{
            heading: ({ children, level }) => {
                switch (level) {
                    case 1:
                        return <h1 className='text-6xl'>{children}</h1>;
                    case 2:
                        return <h2 className='text-5xl'>{children}</h2>;
                    case 3:
                        return <h3 className='text-4xl'>{children}</h3>;
                    case 4:
                        return <h4 className='text-3xl'>{children}</h4>;
                    case 5:
                        return <h5 className='text-2xl'>{children}</h5>;
                    case 6:
                        return <h6 className='text-xl'>{children}</h6>;
                    default:
                        return null;
                }
            },
            link: ({ children, url }) => {
                const isPDF = url.endsWith('.pdf');
                const handleDownload = (e) => {
                    e.preventDefault();
                    fetch(url)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.blob();
                        })
                        .then((blob) => {
                            const link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = url.split('/').pop(); // Use the filename from the URL
                            link.click();
                            window.URL.revokeObjectURL(link.href); // Clean up URL object
                        })
                        .catch((error) => {
                            console.error('Error downloading file:', error);
                        });
                };
                return (
                    <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    >
                        {isPDF ? (
                            <button type='button' className="btn-primary" onClick={handleDownload}>
                                {children}
                            </button>
                        ) : (
                            <button type='button' className="btn-primary" onClick={() => handleLink(url)}>
                                {children}
                            </button>
                        )}
                    </motion.button>
                );
            },
            paragraph: ({ children }) => {
                return <p className='text-lg'>{children}</p>;
            },
            list: ({ children, format }) => {
                switch (format) {
                    case 'ordered':
                        return <ul className="list-decimal ml-5">{children}</ul>;
                    case 'unordered':
                        return <ol className="list-disc ml-5">{children}</ol>;
                    default:
                        return null;
                }
            },
            // image: ({ children, image }) => <>Image</>,
        }}
        modifiers={{
            bold: ({ children }) => <strong className='font-bold'>{children}</strong>,
            italic: ({ children }) => <span className="italic">{children}</span>,
            underline: ({ children }) => <span className='underline'>{children}</span>,
            strikethrough: ({ children }) => <span className='line-through'>{children}</span>,
            // code: ({ children }) => <code>{children}</code>,
        }}
    />;
};

export default AdvancedBlockRendererClient;
