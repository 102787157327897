import React from 'react';
import { RouterProvider } from "react-router-dom";
import { webappRouter } from "./routers/router";

function App() {
  return (
    <RouterProvider router={webappRouter} />
  )
}

export default App
