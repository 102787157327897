import React from 'react'
import { fetchServiceById, getMediumBannerImageUrl, fetchCompanySettings } from '../utils/hooks'
import AdvancedBlockRendererClient from '../components/core/AdvancedBlockRendererClient'
import { isBrowser, isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';

const ServiceDetail = () => {
	let params = useParams()
	let navigate = useNavigate()
	const [serviceDetail, setServiceDetail] = React.useState(null)
	const [settings, setSettings] = React.useState(null)

	React.useEffect(() => {
		fetchServiceById(params.serviceId, setServiceDetail)
		fetchCompanySettings(setSettings)
	}, [params.serviceId])

	const handleContactUs = (event) => {
		event.preventDefault()
		navigate('/#contact')
	}

	return (
		<>
			{
				serviceDetail && settings
				&& <div className={`container mx-auto flex ${ isBrowser ? 'flex-row-reverse' : 'flex-col' } px-2 py-8 items-start mb-4`}>
					<div className={`lg:max-w-lg lg:w-full md:w-2/3 mb-8 md:mb-0 p-4 ${isBrowser ? 'mt-12' : ''} `}>
						<img className="object-cover object-center rounded-lg mb-4" alt="hero" src={getMediumBannerImageUrl(serviceDetail?.data?.attributes?.Banner?.data?.attributes)} />
						<div className="w-full">
							<div className="flex justify-center md:justify-start">
								{	!isMobile && 
									<motion.button
										whileHover={{ scale: 1.1 }} // Zoom-in effect on hover
										whileTap={{ scale: 0.95 }} // Slight zoom-out on click for interactivity
										transition={{ type: 'spring', stiffness: 400, damping: 10 }}
									>
										<button onClick={handleContactUs} className="inline-flex text-white bg-sky-500 border-0 py-2 px-6 focus:outline-none hover:bg-amber-400 rounded-full text-lg"> Let's have a talk </button>
									</motion.button>
								}
								<a href={`tel:${settings?.contactPhone}`} className="ml-4 inline-flex border-0 py-2 px-2 focus:outline-none rounded text-lg">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 text-green-500">
										<path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
									</svg>
								</a>
								<a href={`mailto:${settings?.contactEmail}`} className="ml-4 inline-flex border-0 py-2 px-2 focus:outline-none rounded text-lg">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-8 text-red-500">
										<path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
										<path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
									</svg>
								</a>
								<a href={`sms:${settings?.contactPhone}`} className="ml-4 inline-flex border-0 py-2 px-2 focus:outline-none rounded text-lg">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-8 text-green-500">
										<path strokeLinecap="round" strokeLinejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
									</svg>
								</a>
							</div>
							{	
								isMobile && <div className='flex justify-center'>
									<button onClick={handleContactUs} className="inline-flex text-white bg-sky-500 border-0 justify-center py-2 px-6 focus:outline-none hover:bg-amber-400 rounded-full text-lg mt-4">Let's have a talk</button>
								</div>
							}
						</div>
					</div>

					<div className="lg:flex-grow flex flex-col md:items-start md:text-left items-center text-center">
						{ !isMobile && <button onClick={(e) => navigate('/service')} className='rounded-full bg-amber-400 hover:bg-sky-500 px-8 text-lg p-2 text-white mb-4'>{"Soylent Service's"}</button>}
						<h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-500">
							{serviceDetail.data.attributes.Title}
						</h1>
						<p className={`leading-relaxed text-gray-600 ${isMobile ? 'text-left' : ''}`}>
							<AdvancedBlockRendererClient content={serviceDetail.data.attributes.Description ?? []} />
						</p>
					</div>
				</div>
			}
		</>
	);
}

export default ServiceDetail;