import React from "react"
import RootLayout from "../Layout"
import Team from '../components/team.component'
import { fetchCompanySettings } from "../utils/hooks";

const TeamPage = () => {
    const [settings, setSettings] = React.useState(null);

    React.useEffect(() => {
        fetchCompanySettings(setSettings);
    }, []);

    return (
        <RootLayout settings={settings}>
            <Team />
        </RootLayout>
    )
}

export default TeamPage