'use client';
import React from 'react';
import { handleRegisterNowBtn } from '../utils/hooks';
import { motion } from 'framer-motion';
import { isBrowser, isMobile } from 'react-device-detect';

const AboutOrg = ({ data }) => {

    const handlerRegisterNow = async (e) => {
        await handleRegisterNowBtn(e, data.freeWorkshopRegLink || '#');
    };

    const handleContactUs = (e) => {
        e.preventDefault();
        window.location = '/#contact';
    };

    return (
        <section className="text-gray-600 body-font">
            <div className="my-4 p-4 text-center">
                <p className={`w-full my-6 ${isMobile ? 'text-5xl' : 'text-7xl' }`}>Welcome to <span className="text-amber-400">Soylent EdTech</span>!!</p>

                <p className="text-2xl mb-4">We Make You & Your Team Job-Ready, & "The Best At What You Do"</p>

                <p className="text-2xl mb-4">We Are Preparing Students For Jobs And Corporations For The Future.</p>

                <div className={`container flex px-5 ${isBrowser ? 'w-2/5 flex-row' : 'w-full flex-col-reverse'} gap-2 justify-between mx-auto p-4`}>
                    <motion.button
                        whileHover={{ scale: 1.1 }} // Zoom-in effect on hover
                        whileTap={{ scale: 0.95 }} // Slight zoom-out on click for interactivity
                        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    >
                        <button onClick={(e) => handlerRegisterNow(e)} type="button" className='btn-primary text-xl'>Register for Workshop Now</button>
                    </motion.button>

                    <motion.button
                        whileHover={{ scale: 1.1 }} // Zoom-in effect on hover
                        whileTap={{ scale: 0.95 }} // Slight zoom-out on click for interactivity
                        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    >
                        <button onClick={(e) => handleContactUs(e)} type="button" className='btn-primary text-xl'>Contact Us</button>
                    </motion.button>
                </div>
            </div>

            <div className="container px-5 py-4 mx-auto">
                <div className="text-center">
                    {/* <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 my-12">
                        <motion.h2 
                            className="sm:text-3xl text-2xl font-medium title-font text-center text-sky-400"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1 }}
                        >
                            We deploy skilled workforce,
                            <br /> Impart industry standard knowledge,
                            <br /> Provide unparalleled solutions.
                        </motion.h2>
                    </div> */}
                    <motion.p
                        className="text-xl leading-relaxed xl:w-3/4 lg:w-3/4 mx-auto"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1.2 }}
                    >
                        At <strong>Soylent EdTech</strong>, we believe in empowering the youth with the skills they need to succeed in today's competitive world. Soylent EdTech was founded with a mission to provide skill training to the youth in smaller, rural areas of the country, enabling them to become part of the skilled employability pool.
                    </motion.p>
                </div>
            </div>

            



            {/* <div className="my-8 p-4 text-center">
                <p className="text-2xl">We Make You & Your Team Job-Ready, & "The Best At What You Do"</p>

                <p className="text-2xl mb-8">We Are Preparing Students For Jobs And Corporations For The Future.</p>

                <div className="container flex flex-row px-5 w-2/5 justify-between mx-auto bg-orange-100 p-4">
                    <button type="button" className='btn-primary text-xl'>Register Now</button>
                    <button type="button" className='btn-primary text-xl'>Contact Us</button>
                </div>
            </div> */}





        </section>
    );
}

export default AboutOrg;
