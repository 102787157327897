import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const ErrorPage = ({ errorCode = "404", errorMessage = "Page Not Found" }) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-800 px-4">
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center"
            >
                <motion.h1
                    className="text-9xl font-extrabold text-indigo-600"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.7, type: "spring" }}
                >
                    {errorCode}
                </motion.h1>
                <motion.h2
                    className="text-2xl md:text-4xl font-semibold mt-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.3 }}
                >
                    {errorMessage}
                </motion.h2>
                <motion.p
                    className="text-gray-600 mt-2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    Oops! The page you’re looking for doesn’t exist.
                </motion.p>
            </motion.div>

            <motion.div
                className="mt-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.7 }}
            >
                <Link
                    to="/"
                    className="px-6 py-3 bg-indigo-600 text-white rounded-lg text-lg font-medium shadow-lg hover:bg-indigo-500 focus:ring-4 focus:ring-indigo-300 transition"
                >
                    Go to Soylent EdTech
                </Link>
            </motion.div>
        </div>
    );
};

export default ErrorPage;
