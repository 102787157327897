import React from 'react';
import { config } from '../utils/config';
import { fetchServices } from '../utils/hooks';
import { useNavigate } from "react-router-dom";

const Footer = ({ data }) => {
    const navigate = useNavigate();
    const [service, setService] = React.useState([]);
    const [columns, setCoumns] = React.useState({
        firstColumn: [],
        secondColumn: []
    });

    React.useEffect(() => {
        fetchServices(setService);
    }, []);

    React.useEffect(() => {
        setCoumns({
            firstColumn: service.slice(0, 4),
            secondColumn: service.slice(4, 8)
        });
    }, [service]);

    return (
        <>
            {
                <div className="relative bg-sky-500">
                    <svg
                        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-sky-500"
                        preserveAspectRatio="none"
                        viewBox="0 0 1440 54"
                    >
                        <path
                            fill="currentColor"
                            d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
                        />
                    </svg>
                    <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                        <div className="grid gap-8 row-gap-10 mb-8 lg:grid-cols-5">
                            <div className="md:max-w-md lg:col-span-2">
                                <a
                                    href="/"
                                    aria-label="Go home"
                                    title="Company"
                                    className="inline-flex items-center"
                                >
                                    <img className="bg-slate-100 rounded-lg" src={'/assets/logo.png'} height={48} width={48} alt="logo" />
                                    <span className="ml-2 text-xl font-bold tracking-wide text-white ">{config.setting.siteName}</span>
                                </a>
                                <div className="mt-0 lg:max-w-sm">
                                    {/* <p className="text-gray-700 font-bold ">
                                        We deploy skilled workforce,
                                        <br /> Impart industry standard knowledge,
                                        <br /> Provide unparalleled solutions.
                                    </p> */}
                                    <p className="text-md text-white">Email : {data?.contactEmail} </p>
                                    <p className="text-md text-white mb-2">Phone No. : {data?.contactPhone} </p>
                                    <p className="text-sm text-white">
                                        At Soylent EdTech!, we are committed to empowering the Indian youth. Our vision is to equip them with the skills they need to become independent, self-reliant professionals.
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-3 md:grid-cols-3">
                                <div>
                                    <p className="font-semibold tracking-wide text-gray-700">
                                        Sitelink's
                                    </p>
                                    <ul className="mt-2 space-y-2 text-white">
                                        {
                                            config.footerLinks.map((item, index) => {
                                                return (<li key={`sitelinks-${index}`}>
                                                    <a href={item.href} className="transition-colors duration-300 hover:text-blue-800">{item.title}</a>
                                                </li>)
                                            })
                                        }
                                    </ul>
                                </div>
                                <div>
                                    <p className="font-semibold tracking-wide text-gray-700"> Services </p>
                                    <ul className="mt-2 space-y-2 text-white">
                                        {
                                            service && columns?.firstColumn?.length > 0
                                            && columns?.firstColumn?.map((item, index) => {
                                                return (
                                                    <li key={`service-list-${index}`} className="relative group">
                                                        <a href={`/service/${item.id}`} className="transition-colors duration-300 hover:text-blue-800">{item.title}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                        <li key={`service-list-static-all`} className="relative group">
                                            <a href={`/service`} className="transition-colors duration-300 hover:text-blue-800">All Services</a>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <p className="font-semibold tracking-wide text-gray-700"> Quick Link's </p>
                                    <ul className="mt-2 space-y-2 text-white">
                                        <li key={`service-list-static-all`} className="relative group">
                                            <button onClick={() => window.open(`${data?.freeWorkshopRegLink ?? '#'}`, '_blank')} target="_blank" className="transition-colors duration-300 hover:text-blue-800">Free Workshop</button>
                                        </li>
                                        <li key={`service-list-static-all`} className="relative group">
                                            <button onClick={() => navigate("/#contact")} className="transition-colors duration-300 hover:text-blue-800">Contact Us</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between pt-5 pb-4 border-t border-sky-500 sm:flex-row text-white">
                            <p className="text-sm text-white-600"> © Copyright {data ? data.copyrightYear : "2024"} | All rights reserved.</p>
                            <div className="flex items-center mt-4 space-x-4 sm:mt-0">
                                {
                                    data && data.socialMediaLinks
                                    && data.socialMediaLinks.filter((item) => item.enableFg).map((item, index) => {
                                        return (
                                            <a href={item.hyperlink} key={`social-key-${index}`} className="transition-colors duration-300 text-white hover:text-amber-400" target='_blank'>
                                                {
                                                    item.socialSiteType && item.socialSiteType === 'linkedIn'
                                                    && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                                    </svg>
                                                }
                                                {
                                                    item.socialSiteType && item.socialSiteType === 'facebook'
                                                    && <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                                        <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                                                    </svg>
                                                }
                                                {
                                                    item.socialSiteType && item.socialSiteType === 'twitter/x'
                                                    && <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                                        <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                                                    </svg>
                                                }
                                                {
                                                    item.socialSiteType && item.socialSiteType === 'instagram'
                                                    && <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                                                        <circle cx="15" cy="15" r="4" />
                                                        <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                                                    </svg>
                                                }
                                                {
                                                    item.socialSiteType && item.socialSiteType === 'github'
                                                    && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                                                    </svg>
                                                }
                                                {
                                                    item.socialSiteType && item.socialSiteType === 'pintrest'
                                                    && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pinterest" viewBox="0 0 16 16">
                                                        <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0" />
                                                    </svg>
                                                }
                                                {
                                                    item.socialSiteType && item.socialSiteType === 'youtube'
                                                    && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                                                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                                                    </svg>
                                                }
                                            </a>
                                        )
                                    })
                                }
                                <a href={'https://ecorpin.com/'} target='_blank' rel="license" className="transition-colors duration-300 text-white hover:text-amber-400" title="Designed & Developed by #Ecorpians">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Footer;