import React from "react";
import { Helmet } from 'react-helmet-async';
import RootLayout from "../Layout";
import AnimationPlayer from '../components/animPlayer.component';
import Contact from '../components/contact.component';
import { fetchCompanySettings } from '../utils/hooks';
import AboutOrg from '../components/AboutOrg';
import DataCounter from '../components/dataCounter.component';
import BusinessPartnerSlider from '../components/core/businessPartner.component';
import { useLocation } from 'react-router-dom';

const HomePage = () => {
	const [settings, setSettings] = React.useState(null);
	const location = useLocation();

	React.useEffect(() => {
		if (location.hash === "#contact") {
			const handleScroll = () => {
				const contactSection = document.querySelector("#contact");
				if (contactSection) {
					contactSection.scrollIntoView({ behavior: "smooth" });
				}
			};
			// Delay to ensure DOM availability
			setTimeout(handleScroll, 250);
		}
	}, [location]);

	React.useEffect(() => {
		// fetchUpdatedAnimation(setAnimationData);
		fetchCompanySettings(setSettings);
	}, []);

	return (
		<>
			<Helmet>
				<title>Soylent EdTech</title>
				{/* <meta name="description" content="Welcome to Soylent EdTech" /> */}
			</Helmet>
			<RootLayout settings={settings}>
				<AnimationPlayer src={"/assets/json/edtech.json"} />
				{/* {
					settings?.animationEnableFg && <AnimationPlayer src={`${config.baseUrl}${settings.animation?.File?.url}`} />
				} */}
				<div id='services-category'>
					<AboutOrg data={settings} />
				</div>
				<DataCounter data={settings} />
				<BusinessPartnerSlider />
				<div id='contact'>
					<Contact data={settings} />
				</div>
			</RootLayout>
		</>
	)
}

export default HomePage;