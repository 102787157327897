import React, { Fragment } from 'react'
import Header from '../components/header.component'
import Footer from '../components/footer.component'
import { fetchMaintenanceCheck } from "../utils/hooks"
import MaintenancePage from '../pages/maintenance'
import CustomLoader from '../components/loader/spinner'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RootLayout = ({ children, settings }) => {
    const [maintenanceCheck, setMaintenanceCheck] = React.useState(null)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        fetchMaintenanceCheck(setMaintenanceCheck, setLoading)
    }, [])

    return (
        <Fragment>
            {
                loading === true && <CustomLoader />
            }
            {
                maintenanceCheck === true 
                && <MaintenancePage />
            }
            {
                maintenanceCheck === false && loading === false
                && <Fragment>
                    <Header />
                    {children}
                    <Footer data={settings} />
                </Fragment>
            }
        </Fragment>
    )
}

export default RootLayout