import React, { useEffect, useRef } from "react";
import { CountUp } from "countup.js";
import { motion } from 'framer-motion';
import { handleRegisterNowBtn } from "../utils/hooks";
import { isBrowser, isMobile } from "react-device-detect";

const DataCounter = ({ data }) => {
    const workingHoursRef = useRef(null);
    const participantsRef = useRef(null);
    const observer = useRef(null);

    const handlerRegisterNow = async (e) => {
        await handleRegisterNowBtn(e, data.freeWorkshopRegLink || '#');
    };

    const handleContactUs = (e) => {
        e.preventDefault();
        window.location = '/#contact';
    };

    useEffect(() => {
        observer.current = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (workingHoursRef.current) {
                            const countUpWorkingHours = new CountUp(workingHoursRef.current, data?.workingHours ?? 0);
                            if (!countUpWorkingHours.error) {
                                countUpWorkingHours.start();
                            } else {
                                console.error(countUpWorkingHours.error);
                            }
                        }
                        if (participantsRef.current) {
                            const countUpParticipants = new CountUp(participantsRef.current, data?.participants ?? 0);
                            if (!countUpParticipants.error) {
                                countUpParticipants.start();
                            } else {
                                console.error(countUpParticipants.error);
                            }
                        }
                        observer.current.disconnect();
                    }
                });
            },
            { threshold: 0.4 } // Trigger when 10% of the component is visible
        );
        if (workingHoursRef.current && participantsRef.current) {
            observer.current.observe(workingHoursRef.current);
            observer.current.observe(participantsRef.current);
        }
        return () => observer.current.disconnect();
    }, [data]);

    return (
        <div className="py-16 lg:py-24">
            <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <svg
                                viewBox="0 0 52 24"
                                fill="currentColor"
                                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-sky-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                            >
                                <defs>
                                    <pattern
                                        id="d5589eeb-3fca-4f01-ac3e-983224745704"
                                        x="0"
                                        y="0"
                                        width=".135"
                                        height=".30"
                                    >
                                        <circle cx="1" cy="1" r=".7" />
                                    </pattern>
                                </defs>
                                <rect
                                    fill="url(#d5589eeb-3fca-4f01-ac3e-983224745704)"
                                    width="52"
                                    height="24"
                                />
                            </svg>
                            <span className="relative">Empowering</span>
                        </span>{' '}
                        Minds & Shaping Futures
                    </h2>
                </div>

                <div className={`${isBrowser ? 'w-4/5' : 'w-full'} mx-auto`}>
                    <p className="text-base text-gray-700 md:text-lg text-center">
                        <motion.p
                            className="text-xl mb-8 text-left"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 3 }}
                        >
                            While, in 2024 everyone's struggling to stay up-to-date with technology, AI & whatnot!! On the other hand, jobs are scarce, competition is fierce, and falling behind is not an option.
                        </motion.p>

                        <motion.p
                            className="text-xl mb-8 text-left"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 4 }}
                        >
                            We've all been there, but Isn't there a shortcut? Must we go the long way, instead of the short one? Well, we hardly think so!!
                        </motion.p>

                        <motion.p
                            className="text-xl mb-8 text-left"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 5 }}
                        >
                            We at Soylent Edtech, offer to guide the students through the thick & thin of their journey. Pursue your dreams, learn from the experts & BE THE EXPERTS!!
                        </motion.p>
                    </p>
                </div>

                <div className="relative w-full p-px mx-auto mb-4 overflow-hidden transition-shadow duration-300 border rounded lg:mb-8 lg:max-w-4xl group hover:shadow-xl">
                    <div className="absolute bottom-0 left-0 w-full h-1 duration-300 origin-left transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    <div className="absolute bottom-0 left-0 w-1 h-full duration-300 origin-bottom transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
                    <div className="absolute top-0 left-0 w-full h-1 duration-300 origin-right transform scale-x-0 bg-deep-purple-accent-400 group-hover:scale-x-100" />
                    <div className="absolute bottom-0 right-0 w-1 h-full duration-300 origin-top transform scale-y-0 bg-deep-purple-accent-400 group-hover:scale-y-100" />
                    <div className="relative flex flex-col items-center h-full py-10 duration-300 bg-white rounded-sm transition-color sm:items-stretch sm:flex-row">
                        <div className="px-4 py-4 text-center sm:w-1/2 sm:px-12 sm:py-8">
                            <h6 className="text-3xl font-bold text-sky-500 sm:text-4xl md:text-5xl" ref={workingHoursRef}>
                                0 +
                            </h6>
                            <p className="text-xs sm:text-sm md:text-base">
                                hours of tailored training
                            </p>
                        </div>
                        <div className="w-40 h-1 mt-4 mb-4 transition duration-300 transform bg-gray-300 rounded-full group-hover:bg-deep-purple-accent-400 group-hover:scale-110 sm:mt-0 sm:mb-0 sm:h-auto sm:w-1" />
                        <div className="px-4 py-4 text-center sm:w-1/2 sm:px-12 sm:py-8">
                            <h6 className="text-3xl font-bold text-sky-500 sm:text-4xl md:text-5xl" ref={participantsRef}>
                                0 +
                            </h6>
                            <p className="text-xs sm:text-sm md:text-base">
                                {/* participants from rural areas of the country. */}
                                participants trained from all walks of life
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4">
                    <div className="mx-auto mb-4 text-gray-600 sm:text-center lg:mb-6 md:px-16">
                        <p className="text-2xl sm:text-xl md:text-2xl">
                            Give a boost to your skill and lead the corporate world from the front.
                            <br />
                            <br />
                            Make the next step toward your personal and professional goals with Soylent EdTech.
                        </p>

                        <p className={`text-lg sm:text-lg md:text-lg ${isMobile ? 'mt-8' : 'mt-4'}`}>
                            If you’re fine with being stuck in the same place, facing rejection, or waiting endlessly, maybe this isn’t for you.
                            <br />
                            But If you want to change the scenery, let's get to work.
                        </p>
                    </div>
                </div>
            </div>
            <div className={`container flex px-5 ${isBrowser ? 'w-2/5 flex-row' : 'w-full flex-col-reverse'} gap-2 justify-between mx-auto p-4`}>
                <motion.button
                    whileHover={{ scale: 1.1 }} // Zoom-in effect on hover
                    whileTap={{ scale: 0.95 }} // Slight zoom-out on click for interactivity
                    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                >
                    <button onClick={(e) => handlerRegisterNow(e)} type="button" className='btn-primary text-xl'>Register for Workshop Now</button>
                </motion.button>

                <motion.button
                    whileHover={{ scale: 1.1 }} // Zoom-in effect on hover
                    whileTap={{ scale: 0.95 }} // Slight zoom-out on click for interactivity
                    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                >
                    <button onClick={(e) => handleContactUs(e)} type="button" className='btn-primary text-xl'>Contact Us</button>
                </motion.button>
            </div>
        </div>
    );
};

export default DataCounter;

