import React from 'react';
import RootLayout from "../Layout";
import { Outlet } from "react-router-dom";
import { motion } from 'framer-motion';
import { fetchServices, fetchCompanySettings } from '../utils/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const ServicePage = () => {
	const navigate = useNavigate();
	let { serviceId } = useParams()
	const [services, setServices] = React.useState([]);
    const [settings, setSettings] = React.useState(null);

	React.useEffect(() => {
		fetchServices(setServices);
        fetchCompanySettings(setSettings);
	}, []);

	const handleExploreService = (event, serviceId) => {
		event.preventDefault();
		navigate(`/service/${serviceId}`)
	};

	return (
		<RootLayout settings={settings}>
			{
				serviceId === undefined 
				&& <motion.div
					initial={{ opacity: 0, y: -10 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: -10 }}
					className={`static w-full ${isMobile ? 'h-lvh' : 'h-full'} bg-white shadow-lg z-50 overflow-auto [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#0ea5e9_100%)]`}
				>
					<div className="container px-4 mb-8 mx-auto text-gray-600 body-font">
						<div className="flex flex-wrap -m-2">
							<div className="w-full p-2 mb-8 h-[70lvh]">
								<div className="p-0 h-full">
									<h2 className="title-font font-medium text-2xl ml-4 mb-4">Exprore Our Services </h2>
									<div className="flex flex-wrap mt-4">
										{
											services.length > 0
											&& services.filter((item) => item.enableFg).map(service => (
												<motion.div
													key={service.id}
													className="p-4 w-full md:w-1/2 lg:w-1/3 cursor-pointer"
													onClick={(e) => handleExploreService(e, service.id)}
													whileHover={{ scale: 1.1 }} // Zoom-in effect on hover
													whileTap={{ scale: 0.95 }} // Slight zoom-out on click for interactivity
													transition={{ type: 'spring', stiffness: 400, damping: 10 }}
												>
													<div className="h-full bg-sky-300 p-4 rounded-lg shadow-lg hover:bg-amber-400">
														<p className="text-white text-medium font-medium title-font">{service.title} <span className='text-indigo-900'> - Enrolling</span></p>
														<p className="text-sm">Free workshop available</p>
													</div>
												</motion.div>
											))
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</motion.div>
			}
			<Outlet />
		</RootLayout>
	);
}

export default ServicePage;